const tabsetClasses = {
  SELECTED_TAB: 'show',
  STICKY_NAVBAR: 'sticky-navbar',

}

// Function for navigation
export function navMenu() {
  let tabsHeaders = document.querySelectorAll('.js-menu-tab'),
      tabsContainers = document.querySelectorAll('.js-navbar-submenu'),
      birchbox = document.getElementsByClassName('js-birchbox')[0],
      menuNav = document.getElementsByClassName('js-menu-nav')[0],
      menuTitle = document.getElementsByClassName('js-main-menu')[0],
      customHref = document.getElementsByClassName('js-custom-href')[0],
      windowPosition, setWindowPosition, tabsContainerOffset,screenWidth;

  menuTitle.addEventListener('click', hideSelectedContent);

  // Function for navigation hide content
  function hideSelectedContent () {
    for (let tabsContainer of tabsContainers) {
      if (tabsContainer.classList.contains (tabsetClasses.SELECTED_TAB)) {
        tabsContainer.classList.remove(tabsetClasses.SELECTED_TAB);
        menuTitle.classList.remove(tabsetClasses.SELECTED_TAB);
        for (let tabsHeader of tabsHeaders) {
          tabsHeader.classList.remove('active');
        }
      }
    }
  }

  // For sticky navigation
  window.addEventListener("scroll", function (event) {
   let scrollTop = menuNav.offsetHeight;
   if (window.pageYOffset >= scrollTop) {
     menuNav.classList.add(tabsetClasses.STICKY_NAVBAR);
   } else {
     menuNav.classList.remove(tabsetClasses.STICKY_NAVBAR);
   }
  }, false);

  //hide the menu when clicked on the outside
  birchbox.addEventListener("click", function () {
    hideSelectedContent();
  }, false);
  menuNav.addEventListener("click", function (ev) {
      ev.stopPropagation(); //this is important! If removed, you'll get both alerts
  }, false);

  for (let tabsHeader of tabsHeaders) {
    tabsHeader.addEventListener('click', showSelectedContent, false);
  }

  // Function for navigation show content
  function showSelectedContent () {
    if (this.classList.contains('active')){
      hideSelectedContent();
      this.classList.remove ('active');
      return;
    }

    for (let tabsContainer1 of tabsContainers) {
      tabsContainer1.classList.remove(tabsetClasses.SELECTED_TAB);
    }

    for (let tabsHeader of tabsHeaders) {
      tabsHeader.classList.remove('active');
    }

    this.classList.add ('active');
    this.classList.remove (tabsetClasses.SELECTED_TAB);
    for (let tabsContainer of tabsContainers) {
      if (this.dataset.set == tabsContainer.id) {
        if (tabsContainer.classList.contains (tabsetClasses.SELECTED_TAB)) {
          tabsContainer.classList.remove (tabsetClasses.SELECTED_TAB);
          menuTitle.classList.remove (tabsetClasses.SELECTED_TAB);
        } else {
          tabsContainer.classList.add (tabsetClasses.SELECTED_TAB);
          menuTitle.classList.add (tabsetClasses.SELECTED_TAB);
          triggerScroll(tabsContainer);
        }
      }
    }
  }

  // Hide navigation on Scroll Functionality
  function triggerScroll (tabsContainer) {
    tabsContainerOffset = tabsContainer.offsetTop;
    windowPosition = window.pageYOffset;
    setWindowPosition = windowPosition + 150;
    screenWidth = screen.width;
    window.addEventListener('scroll', function() {
      if (screenWidth >= 480) {
        if (window.pageYOffset >= setWindowPosition) {
          hideSelectedContent();
        } else if (window.pageYOffset <= windowPosition) {
          hideSelectedContent();
        }
      }
    });
  }

  /*
  ** Reload the page on click of subscribe button
  */
 if (customHref) {
   customHref.addEventListener("click", function () {
     location.reload();
    }, false);
  }
}
