import { tns } from '../js/vendor/tiny-slider/tiny-slider.module';

let slideAtInterval = 3750;
/**
* Functionality for all carousels.
*/
export function sliderThumbnail() {
  let subscriptionSliderElement = document.getElementById('js-subscription-slider'),
    noSliderVariant = document.getElementsByClassName('js-birchbox__subscription--v4')[0],
    hero_slides = document.getElementById('js-hero-slider'),
    hero_slides_bottom = document.getElementById('js-hero-slider-bottom'),
    hero_slider,
    hero_slider_bottom,
    subscriptionSlider;
  const SCREEN_WIDTH = 768;
  const SCREEN_WIDTH_NINE = 900;

  if (subscriptionSliderElement && !noSliderVariant) {
    if (window.innerWidth < SCREEN_WIDTH) {
      subscriptionSlider = tns({
        container: subscriptionSliderElement,
        items: 1,
        speed: 200,
        loop: false,
        startIndex: 1,
        "edgePadding": 30,
        "swipeAngle": false,
        gutter: 10,
        responsive: {
          "768": {
            "items": 3
          }
        }
      });

      subscriptionSlider.events.on('transitionEnd', function () {
        let allSubscriptionSlides;

        if (subscriptionSliderElement) {
          allSubscriptionSlides = subscriptionSliderElement.getElementsByTagName(LI);
        }
        
        for (let j = 0; j < allSubscriptionSlides.length; j++) {
          allSubscriptionSlides[j].classList.remove('active');
          if (allSubscriptionSlides[j].classList.contains('tns-slide-active'))
            allSubscriptionSlides[j].classList.add('active');
        }
      });
    }
  }

  if (hero_slides && (window.innerWidth < SCREEN_WIDTH)) {
      hero_slider = tns({
        container: hero_slides,
        items: 1,
        slideBy: "page",
        speed: 1000,
        loop: true,
        autoplay: true,
        autoplayTimeout: slideAtInterval
      });
  }

  if (hero_slides_bottom && (window.innerWidth < SCREEN_WIDTH)) {
    hero_slider_bottom = tns({
      container: hero_slides_bottom,
      items: 1,
      slideBy: "page",
      speed: 1000,
      loop: true,
      autoplay: true,
      autoplayTimeout: slideAtInterval
    });
}

  //Removing prev,next text from buttons
  let buttons = document.querySelectorAll('.tns-outer .tns-controls button');
  for (var button of buttons) {
    button.innerText = "";
  }
}
