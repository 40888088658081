/**
 * This logic adds navigation to pages.
 */

import menuNavtpl from '../../hbs-templates/menu-nav.hbs';
import logoNavtpl from '../../hbs-templates/logo-nav.hbs';
import logoNavtplV2 from '../../hbs-templates/logo-nav-two.hbs';
import logoNavtplV3 from '../../hbs-templates/logo-nav-three.hbs';
import logoNavMenstpl from '../../hbs-templates/logo-nav-men.hbs';
import logoNavQuiztpl from '../../hbs-templates/logo-nav-quiz.hbs';
import logoNavMensQuiztpl from '../../hbs-templates/logo-nav-quiz-man.hbs';
import blogNavMenstpl from '../../hbs-templates/blog-nav-mens.hbs';
import blogNavWomenstpl from '../../hbs-templates/blog-nav-womens.hbs';
import menuNavV1tpl from '../../hbs-templates/menu-nav-v1.hbs';
import offerStickytpl from '../../hbs-templates/offer-sticky.hbs';
import logoNavtplFB from '../../hbs-templates/logo-nav-bf.hbs';
//import menunav8 from '../../hbs-templates/menu-nav-v8.hbs';

export function navbar() {
  let hbsMenuNav = document.getElementsByClassName('js-hbs-menuNav')[0],
      hbsLogoNav = document.getElementsByClassName('js-hbs-logoNav')[0],
      hbsLogoNav2 = document.getElementsByClassName('js-hbs-logoNavTwo')[0],
      hbsLogoNav3 = document.getElementsByClassName('js-hbs-logoNavThree')[0],
      hbsLogoNavMens = document.getElementsByClassName('js-hbs-logoNavMens')[0],
      hbsLogoNavMensQuiz = document.getElementsByClassName('js-hbs-logoNavMensQuiz')[0],
      hbsLogoNavQuiz = document.getElementsByClassName('js-hbs-logoNavQuiz')[0],
      hbsBlogNavMens = document.getElementsByClassName('js-hbs-blogNavMens')[0],
      hbsBlogNavWomens = document.getElementsByClassName('js-hbs-blogNavWomens')[0],
      hbsMenuNavV1 = document.getElementsByClassName('js-hbs-menuNavV1')[0],
      hbsOfferSticky = document.getElementsByClassName('js-hbs-offerSticky')[0],
      hbsLogoNavFB = document.getElementsByClassName('js-hbs-logoNavFB')[0];

      //hbasMenuV8 = document.getElementsByClassName('js-hbs-menuPageV8')[0];

  if (hbsMenuNav) {
    hbsMenuNav.innerHTML = menuNavtpl();
  }

  if (hbsLogoNavFB) {
    hbsLogoNavFB.innerHTML = logoNavtplFB();
  }

  if (hbsLogoNav) {
    hbsLogoNav.innerHTML = logoNavtpl();
  }

  if (hbsLogoNav2) {
    hbsLogoNav2.innerHTML = logoNavtplV2();
  }

  if (hbsLogoNav3) {
    hbsLogoNav3.innerHTML = logoNavtplV3();
  }

  if (hbsLogoNavMens) {
    hbsLogoNavMens.innerHTML = logoNavMenstpl();
  }

  if (hbsBlogNavMens) {
    hbsBlogNavMens.innerHTML = blogNavMenstpl();
  }

  if (hbsLogoNavMensQuiz) {
    hbsLogoNavMensQuiz.innerHTML = logoNavMensQuiztpl();
  }

  if (hbsLogoNavQuiz) {
    hbsLogoNavQuiz.innerHTML = logoNavQuiztpl();
  }

  if (hbsBlogNavWomens) {
    hbsBlogNavWomens.innerHTML = blogNavWomenstpl();
  }

  if (hbsMenuNavV1) {
    hbsMenuNavV1.innerHTML = menuNavV1tpl();
  }

  if (hbsOfferSticky) {
    hbsOfferSticky.innerHTML = offerStickytpl();
  }

  let blogNav = document.getElementsByClassName('blog-nav')[0];

  if (blogNav) {
    window.addEventListener("scroll", function() {
      if (this.pageYOffset > 0) {
        blogNav.classList.add("blog-nav__box-shadow");
      } else {
        blogNav.classList.remove("blog-nav__box-shadow");
      }
    });
  }
}
