const Classes = {
  PLAN_SELECTED: 'plan-selected',
}

export function planDynamicLinks() {
  let selectPlans = document.getElementsByClassName('js-select-plan'),
      getLink, getUtmParams;
  const qs = location.search.substr(1) ? location.search.substr(1) : "";

  const SCREEN_WIDTH = 768;

  if (window.innerWidth < SCREEN_WIDTH ) {

    for (let selectPlan of selectPlans) {
      selectPlan.addEventListener('click', selectedPlan, false);
    }

    function selectedPlan() {
      removeSelectedPlan()

      this.classList.add(Classes.PLAN_SELECTED);

      let replacePlanUrl = document.getElementsByClassName('js-selected-plan-link')[0],
          setUtmParams;

      getUtmParams = replacePlanUrl.getAttribute('href');
      setUtmParams = qs;

      // Get Link need to add to CTA
      getLink = this.dataset.link;
      replacePlanUrl.setAttribute('href', `${getLink}&${setUtmParams}`);
    }
  } else {
    removeSelectedPlan();
  }

 // Function to remove selected plan
  function removeSelectedPlan() {
    for (let selectPlan of selectPlans) {
      if (selectPlan.classList.contains(Classes.PLAN_SELECTED)) {
        selectPlan.classList.remove(Classes.PLAN_SELECTED);
      }
    }
  }
}
