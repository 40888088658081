export function hamburger() {
  let navbar = document.getElementById("js-nav"),
      hamburger = document.getElementById("js-open-nav"),
      closeNav = document.getElementById("js-close-nav"),
      backdrop = document.getElementsByClassName("js-backdrop")[0];
  hamburger.addEventListener('click', function() {
    navbar.classList.remove('slide');
    backdrop.classList.remove('hide');
    document.getElementsByTagName('body')[0].style = 'overflow-y: hidden';
    document.getElementsByTagName('html')[0].style = 'position: fixed';
  });
  closeNav.addEventListener('click', function() {
    navbar.classList.add('slide');
    backdrop.classList.add('hide');
    document.getElementsByTagName('body')[0].style = 'overflow-y: auto';
    document.getElementsByTagName('html')[0].style = 'position: static';
  });
}
