import * as utmAPI from '../js/utm-api.js';
const Classes = {
  PLAN_SELECTED: 'plan-selected',
}

export function planDynamicLinksJoin() {
  let selectPlans = document.getElementsByClassName('js-select-plan-join'),
    replaceCodeUrls = document.getElementsByClassName('js-select-code'),
    oneMonth = document.getElementsByClassName('js-one-month')[0],
    sixMonth = document.getElementsByClassName('js-six-month')[0],
    carouselPlans = document.getElementsByClassName('js-carousel-btn'),
    carouselCodes = document.getElementsByClassName('js-carousel-arrow'),
    skipPlan = document.getElementById('js-skip-plan'),
    slider_div =  document.querySelector('.js-hero__slider'),
    queryParams = location.search.substr(1) ? location.search.substr(1) : location.search.substr(1),
    getLink, getUtmParams, pathLink, cartLink, pId, href, getLink_new, setNewUtmParams, hrefUrl;

  for (let selectPlan of selectPlans) {
    selectPlan.addEventListener('click', selectedPlan, false);

    if (slider_div) {
      selectPlan.addEventListener('touchend', selectedPlanSwipe, false);
    }
  }

  for (let carouselPlan of carouselPlans) {
    if (slider_div) {
      carouselPlan.addEventListener('click', selectedPlanSwipe, false);
    }    
  }

  if  ( document.getElementsByClassName('js-select-code')[0] ) {
    for (let replaceCodeUrl of replaceCodeUrls ) {
      replaceCodeUrl.addEventListener('click', prepareCustomLink, false);
      replaceCodeUrl.addEventListener('touchend', prepareCustomLinkSwipe, false);
    }

    for (let carouselCode of carouselCodes) {
      carouselCode.addEventListener('click', prepareCustomLinkSwipe, false);
    }
  }

  if (skipPlan) {
    skipPlan.addEventListener('click', removeCodeFromLink, false);
  }

  function selectedPlan(e) {
    removeSelectedPlan()
    this.classList.add(Classes.PLAN_SELECTED);

    let replacePlanUrls = document.querySelectorAll('.js-selected-product-join'),
      setUtmParams;

    for (let replacePlanUrl of replacePlanUrls) {
      getUtmParams = replacePlanUrl.getAttribute('href');
      setUtmParams = getUtmParams.split('?')[1].split('&').splice(1).join('&');
      getLink = this.dataset.link;
      replacePlanUrl.setAttribute('href', `${getLink}&${setUtmParams}`);

      pathLink = getLink.split('='),
      cartLink = pathLink[0],
      pId = pathLink[1];
    }

  }

  function selectedPlanSwipe() {
    setTimeout(function () {
      let slider_btn = document.querySelector('.tns-slide-active');      

      if (slider_btn) {
        let selectedPlanArrow = slider_btn.querySelector('.js-select-plan-join');

        removeSelectedPlan()
        selectedPlanArrow.classList.add(Classes.PLAN_SELECTED);

        let replacePlanUrls = document.querySelectorAll('.js-selected-product-join'),
          setUtmParams;

        for (let replacePlanUrl of replacePlanUrls) {
          getUtmParams = replacePlanUrl.getAttribute('href');
          setUtmParams = getUtmParams.split('?')[1].split('&').splice(1).join('&');
          getLink = selectedPlanArrow.dataset.link;
          replacePlanUrl.setAttribute('href', `${getLink}&${setUtmParams}`);

          pathLink = getLink.split('='),
          cartLink = pathLink[0],
          pId = pathLink[1];
        }
      }
    }, 200);
  }

  // Function to remove selected plan
  function removeSelectedPlan() {
    for (let selectPlan of selectPlans) {
      if (selectPlan.classList.contains(Classes.PLAN_SELECTED)) {
        selectPlan.classList.remove(Classes.PLAN_SELECTED);
      }
    }
  }

  // Function to remove selected Code
  function removeSelectedCode() {
    for (let replaceCodeUrl of replaceCodeUrls) {
      if (replaceCodeUrl.classList.contains(Classes.PLAN_SELECTED)) {
        replaceCodeUrl.classList.remove(Classes.PLAN_SELECTED);
      }
    }
  }

  // Function to add code to the product ID
  function prepareCustomLink() {
    let replacePlanUrls = document.querySelectorAll('.js-selected-product-join'),
      setUtmParams;
      removeSelectedCode();
      this.classList.add(Classes.PLAN_SELECTED);

    for (let replaceCodeUrl of replaceCodeUrls) {
      getUtmParams = this.getAttribute('data-link');
      setUtmParams = getUtmParams.split('?')[1].split('&').splice(1).join('&');

      for (let replacePlanUrl of replacePlanUrls) {
        getLink = replacePlanUrl.dataset.link;
        hrefUrl = `${getLink}&${setUtmParams}&${queryParams}`;
        replacePlanUrl.setAttribute('href', hrefUrl);
      }
    }
  }

  //Function to add code in carousel
  function prepareCustomLinkSwipe() {
    setTimeout(function () {
      let slider_btn = document.querySelector('.tns-slide-active');

        if (slider_btn) {
          let selectedPlanArrow = slider_btn.querySelector('.js-select-code');

          removeSelectedPlan()
          selectedPlanArrow.classList.add(Classes.PLAN_SELECTED);

          let replacePlanUrls = document.querySelectorAll('.js-selected-product-join'),
            setUtmParams;

          for (let replaceCodeUrl of replaceCodeUrls) {
            getUtmParams = selectedPlanArrow.getAttribute('data-link');
            setUtmParams = getUtmParams.split('?')[1].split('&').splice(1).join('&');

            for (let replacePlanUrl of replacePlanUrls) {
              getLink = replacePlanUrl.dataset.link;
              hrefUrl = `${getLink}&${setUtmParams}&${queryParams}`;
              replacePlanUrl.setAttribute('href', hrefUrl);
            }
          }
        }
    }, 200);
  }

  //Remove the code on Skip PLAN
  function removeCodeFromLink () {
    let replacePlanUrls = document.querySelectorAll('.js-selected-product-join'),
    getDataLink;

    for (let replacePlanUrl of replacePlanUrls) {
      getDataLink = replacePlanUrl.dataset.link;
      getDataLink = `${getDataLink}&${queryParams}`;
      replacePlanUrl.setAttribute('href', getDataLink);
    }
  }
}
