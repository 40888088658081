
export function OfferStickyHeader() {

  let hbsCloseBtn = document.getElementsByClassName('js-banner-close')[0],
      hbsSiteBanner = document.getElementsByClassName('js-site-banner-wrapper')[0],
      hbsBlogNavWomens = document.getElementsByClassName('js-hbs-blogNavWomens')[0];

  if (hbsCloseBtn) {
    hbsCloseBtn.addEventListener('click', hideBanner);
  }

  function hideBanner() {
      hbsBlogNavWomens.classList.add('logo-container');
      hbsSiteBanner.classList.add('offer-banner');
  }

}
