/**
 * This logic adds Signup form to pages.
 */
import signupFormtpl from '../../hbs-templates/tracking-pixel.hbs';
import gtmtpl from '../../hbs-templates/gtm.hbs';

export function trackingPixel() {
  let hbsTrackerPixel = document.getElementsByClassName('js-hbs-tracker-pixel')[0];
  let hbsGtm = document.getElementsByClassName('js-hbs-gtm')[0];

  if (hbsTrackerPixel) {
    hbsTrackerPixel.innerHTML = signupFormtpl();
  }

  if (hbsGtm) {
    hbsGtm.innerHTML = gtmtpl();
  }
}
