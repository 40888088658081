export function collapsable() {
    let headcontainers = getElementsByClassName(document.body,"header__container"),
        i;

    for (let headcontainer of headcontainers) {
      headcontainer.addEventListener("click", function() {
        let title = this.children[0];

        if (title.classList.length<2) {
          let panelOlds =getElementsByClassName(document.body,"panel");

          for (let panelOld of panelOlds) {
            panelOld.style.maxHeight = 0 +  "px";
            let elem = panelOld.parentElement.children[0];
            elem.children[0].classList.remove("active");
            elem.children[1].children[0].setAttribute('style', 'transform: rotate(0deg);transform-origin: center center 0px;');
          }
          title.classList.toggle("active");
          this.children[1].children[0].setAttribute('style', 'transform: rotate(180deg);transform-origin: center center 0px;');
          let panel = title.parentElement.nextElementSibling;
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
        else {
          let panelOlds = getElementsByClassName(document.body,"panel");
          for (let panelOld of panelOlds) {
            panelOld.style.maxHeight = 0 +  "px";
            let elem = panelOld.parentElement.children[0];
            elem.children[0].classList.remove("active");
            this.children[1].children[0].setAttribute('style', 'transform: rotate(0deg);transform-origin: center center 0px;');
          }
        }
      });
    }

    function getElementsByClassName(node, classname) {
      let a = [],
          re = new RegExp(`(^| )+(${classname})+( |$)`),
          els = node.getElementsByTagName("*");
          for (let i=0,j=els.length; i<j; i++)
          if (re.test(els[i].className))a.push(els[i]);
      return a;
  }
}
