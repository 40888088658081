/*========================================================================================================*/
// utmAPI
/*========================================================================================================*/

var utmAPI = {
  getUtmParams: function(customParamsObj) {
    // ex. customParamsObj
    // {propName: 'value'}

    var utmParamsArr = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_variant', 'utm_sd', 'utm_type', 'utm_aid', 'utm_lang'];
    var utmParamsObj = {};
    var key;
    var value;

    if (customParamsObj) {
      for (const prop in customParamsObj) {
        key = prop;
        value = customParamsObj[prop];

        utmParamsObj[key] = value;
      }
    }

    for (var i = 0; i < utmParamsArr.length; i++) {
      key = utmParamsArr[i];
      value = getQueryVariable(utmParamsArr[i]);

      utmParamsObj[key] = value;
    }

    return utmParamsObj;
  },
  createUtmParamString: function(queryParamKeysArray) {
    let result = "";

    for (let i = 0; i < queryParamKeysArray.length; i++) {
      result += `&${queryParamKeysArray[i]}=${utmAPI.getCookieValue(queryParamKeysArray[i])}`;
    }

    return result;
  },
  getCookieValue: function(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }

    return null;
  },
  hrefDelay: function hrefDelay(URL, time) {
    setTimeout(function() {
      window.location = URL
    }, time);
  },
  queryStringToObject: function() {
    let queryString = location.search.substr(1);
    let pairs = queryString.split('&');
    let result = {};

    pairs.forEach(function (pair) {
      pair = pair.split('=');
      result[pair[0]] = decodeURIComponent(pair[1] || '');
    });

    return result;
  },
  setCookie: function(params, doExpires, domain) {
    if (doExpires) {
      let date = new Date();
      let expires;

      date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
      expires = 'expires=' + date.toUTCString();
      params.forEach(function (param) {
        document.cookie = param + ';' + expires + ";domain=" + domain + ";path=/";
      });
    } else {
      document.cookie = params + ";domain=" + domain + ";path=/";
    }
  },
  encodeAndGetID: function(id) {
    return new Promise(function(resolve, reject) {
      let xhttp = new XMLHttpRequest();

      xhttp.open('POST', `https://ampid.ampush.io/translate?id=${id}&pid=f87418c3-6831-495e-b805-3ed1c85421ae`, true);
      xhttp.onload = function () {
        if (this.status == 200) {
          let encodedId = JSON.parse(xhttp.response).amp_id;

          if (encodedId.indexOf("amp_") !== -1) {
            encodedId = encodedId.replace(/amp_/, "");
          }

          resolve(encodedId);
        } else {
          // Something went wrong (404 etc.)
          reject(new Error(this.statusText));
        }
      };
      xhttp.send();
    });
  }
}

/*========================================================================================================*/
// private functions
/*========================================================================================================*/

function getQueryVariable(variable) {
  var query = document.cookie
  var vars = query.split("; ");

  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    if(pair[0] == variable){
      return pair[1];
    }
  }


  return(false);
}

/*========================================================================================================*/
// add getUtmParams method to window object so it is available on within script tags on html pages for ampush pageview pixels
/*========================================================================================================*/

(function() {
	window.getUtmParams = utmAPI.getUtmParams;
})();

/*========================================================================================================*/
// make utmParams available site wide
/*========================================================================================================*/

export default {
	getUtmParams: utmAPI.getUtmParams,
  getCookieValue: utmAPI.getCookieValue,
  hrefDelay: utmAPI.hrefDelay,
  queryStringToObject: utmAPI.queryStringToObject,
  fireTaboolaBingAdWords: utmAPI.fireTaboolaBingAdWords,
  setCookie: utmAPI.setCookie,
  createUtmParamString: utmAPI.createUtmParamString,
  encodeAndGetID: utmAPI.encodeAndGetID
}
