/**
 * This logic adds footer to pages.
 */
import footertpl from '../../hbs-templates/footer.hbs';
import footerNavtpl from '../../hbs-templates/footer-nav.hbs';

export function footer() {
  let hbsFooter = document.getElementsByClassName('js-hbs-footer')[0],
      hbsFooterNav = document.getElementsByClassName('js-hbs-footer-nav')[0];

  if (hbsFooter) {
    hbsFooter.innerHTML = footertpl();
  }

  if (hbsFooterNav) {
    hbsFooterNav.innerHTML = footerNavtpl();
  }
}
