// import { customTracker } from '../js/custom-tracker';
// import { firePixelTracker } from '../js/tracker';
// import { utmTracker } from '../js/utm-tracker';
import { lazysizes } from '../js/vendor/lazysizes.min';
import { modalOverlay } from '../js/modal-overlay';
import { sliderThumbnail } from '../js/slider-thumbnail';
import { boxSliderThumbnail } from '../js/box-slider-thumbnail';
import { stopJumpiness } from '../js/no-jumpiness-slider';
import { hamburger } from '../js/hamburger';
import { selectSubscription } from '../js/birchbox-lp';
import { navMenu } from '../js/nav-menu';
import { collapsable } from '../js/collapsable';
import { popup } from '../js/popup';
import { planDynamicLinks } from '../js/plan-dynamic-links';
import { planDynamicLinksJoin } from '../js/plan-dynamic-links-join';
import { smoothScroll } from '../js/smooth-scroll';
import { trackUserJourney } from '../js/join-2-user-flow-track';
import { OfferStickyHeader } from '../js/offer-sticky-header';

import '../scss/birchbox-lp-style.scss';

/**
 * HBS template initialization
 */
import { navbar } from '../js/template-handler/navbar';
import { footer } from '../js/template-handler/footer';
import { trackingPixel } from '../js/template-handler/tracking-pixel';

if (!window._babelPolyfill) {
  require("babel-polyfill");
}

/**
 * Manages functionality on Birchbox landing page.
 */
export function BirchBox() {
  new navbar();
  new trackingPixel();
  new footer();
  // new customTracker();
  // new firePixelTracker();
  new collapsable();
  new popup();
  new OfferStickyHeader();
  new smoothScroll({
    duration: 800,
    offset: -10
  });

  if (document.getElementById('js-nav')) {
    new hamburger();
  }

  if (document.getElementsByClassName('js-menu-tab')[0]) {
    new navMenu();
  }

  if (document.getElementsByClassName('js-open-modal')[0]) {
    new modalOverlay();
  }

  if (document.getElementById('js-subscription-slider')) {
    new sliderThumbnail();
    new stopJumpiness();
    new selectSubscription();
  }

  if (document.getElementById('js-hero-slider')) {
    new sliderThumbnail();
  }

  if (document.getElementById('js-box-slider') || document.getElementById('js-box-slider-top')) {
    new boxSliderThumbnail();
    new stopJumpiness();
  }

  if (document.getElementsByClassName('js-select-plan')[0]) {
    new planDynamicLinks();
  }

  if (document.getElementsByClassName('js-select-plan-join')[0]) {
    new planDynamicLinksJoin();
  }

  if (document.getElementsByClassName('js-track-user')[0]) {
    new trackUserJourney();
  }

  if (document.getElementsByClassName('js-step-carousel')) {
    new smoothScroll({
      duration: 800,
      offset: -55
    });
  }

  if (document.getElementsByClassName('join-2-step-offset')) {
    new smoothScroll({
      duration: 800,
      offset: 0
    });
  }

}

window.onload = new BirchBox();
