import { tns } from '../js/vendor/tiny-slider/tiny-slider.module';

let slideAtInterval = 3750;
/**
* Functionality for all carousels.
*/
export function boxSliderThumbnail() {

  let join_slides = document.getElementById('js-box-slider'),
      join_step_slide = document.getElementById('js-box-slider-top'),
      join_step_bottom_slides = document.getElementById('js-box-slider-bottom'),
      join_step_plan = document.getElementById('js-box-slider-plan'),
      join_step_product = document.getElementById('js-box-slider-product');

  // join_slides.ontouchmove = function (e) {
  //   e.preventDefault();
  // }
  let join_slider,
    tabletWidth = 900,maxTabLength = 768,
    drag = true;
  if (window.innerWidth >= tabletWidth) {
    drag = false;
  }
  if (join_slides) {
    join_slider = tns({
      container: join_slides,
      items: 1,
      slideBy: "page",
      speed: 100,
      loop: true,
      nav: true,
      controlsContainer: "#customize-controls"
    });
  }

  if (join_step_slide && (window.innerWidth < maxTabLength)) {
    join_slider = tns({
      container: join_step_slide,
      items: 1,
      slideBy: "page",
      speed: 100,
      loop: true,
      nav: true,
      controlsContainer: "#customize-controls"
    });
  }

  if (join_step_bottom_slides && (window.innerWidth < maxTabLength)) {
    join_slider = tns({
      container: join_step_bottom_slides,
      items: 1,
      slideBy: "page",
      speed: 100,
      loop: true,
      nav: true,
      controlsContainer: "#customize-control"
    });
  }

  if ((join_step_plan) && (window.innerWidth < maxTabLength)) {
    join_slider = tns({
      container: join_step_plan,
      items: 1,
      slideBy: "page",
      speed: 100,
      loop: true,
      nav: true,
      controlsContainer: "#customize-control-plan"
    });
  }

  if ((join_step_product) && (window.innerWidth < maxTabLength)) {
    join_slider = tns({
      container: join_step_product,
      items: 1,
      slideBy: "page",
      speed: 100,
      loop: true,
      nav: true,
      controlsContainer: "#customize-control-product"
    });
  }

  let captionHolder = document.getElementById('slider-caption'),
    carouselMobileTitle = document.getElementById('js-mobile-title');

  if(join_slides) { 
    carouselMobileTitle.innerHTML = document.querySelector('.js-product-title').innerHTML;
    captionHolder.innerHTML = document.querySelector('.tns-slide-active figurecaption').innerHTML;
    
    let info = join_slider.getInfo();

    document.querySelector('[data-controls="next"]').onclick = function () {
      // get slider info
      let indexPrev = info.indexCached,
        indexCurrent = info.index;
  
      // update style based on index
      info.slideItems[indexPrev].classList.remove('active');
      info.slideItems[indexCurrent].classList.add('active');
    };
  
    let customizedFunction = function (info) {
      // direct access to info object
      captionHolder.innerHTML = document.querySelector('.tns-slide-active figurecaption').innerHTML;
      carouselMobileTitle.innerHTML = document.querySelector('.tns-slide-active .js-product-title').innerHTML;
    }
    // bind function to event
    join_slider.events.on('indexChanged', customizedFunction);
  }
}
