export function selectSubscription() {

  let subscriptionSliderElement = document.getElementById('js-subscription-slider'),
    allSubscriptions;

  if (subscriptionSliderElement) {
    allSubscriptions = subscriptionSliderElement.getElementsByTagName('LI');

      for (let allSubscription of allSubscriptions) {
      allSubscription.addEventListener('click', function (e) {
        for (let j = 0; j < allSubscriptions.length; j++) {
          allSubscriptions[j].classList.remove('active');
        }
        e.currentTarget.classList.add('active');
      });
    }
  }
}
