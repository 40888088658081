export function popup() {

  let modalBtns = [...document.querySelectorAll(".button")],  
      closeBtns = [...document.querySelectorAll(".close-pop")],
      joinPage = [...document.getElementsByClassName("join-2-step")],
      body = document.body,
      html = document.getElementsByTagName('html')[0];

  modalBtns.forEach(function (btn) {
    btn.onclick = function () {
      let modal = btn.getAttribute('data-modal');
      document.getElementById(modal).style.display = "block";

      body.classList.add("BodyClass");
      if(joinPage) {
        html.classList.add("htmlScroll");
      }
    }
  });
  
  closeBtns.forEach(function (btn) {
    btn.onclick = function () {
      let modal = btn.closest('.modal');
      modal.style.display = "none";

      body.classList.remove("BodyClass");
      if(joinPage) {
        html.classList.remove("htmlScroll");
      }
    }
  });

  window.onclick = function (event) {
    if (event.target.className === "modal") {
      event.target.style.display = "none";
      body.classList.remove("BodyClass");
      if(joinPage) {
        html.classList.remove("htmlScroll");
      }
    }
  }
}
