/**
* stopJumpiness for all carousels.
*/
export function stopJumpiness() {
  let carousels = document.getElementsByClassName('tns-outer');

  //Only scroll when scroll in Y Axis direction.
  (function(d){
     var ce=function(e,n){var a=document.createEvent("CustomEvent");a.initCustomEvent(n,true,true,e.target);e.target.dispatchEvent(a);a=null;return false},
     nm=true,sp={x:0,y:0},ep={x:0,y:0},
     touch={
      touchstart:function(e){sp={x:e.touches[0].pageX,y:e.touches[0].pageY}},
      touchmove:function(e){nm=false;ep={x:e.touches[0].pageX,y:e.touches[0].pageY}},
      touchend:function(e){if(nm){ce(e,'fc')}else{var x=ep.x-sp.x,xr=Math.abs(x),y=ep.y-sp.y,yr=Math.abs(y);if(Math.max(xr,yr)>20){ce(e,(xr>yr?(x<0?'swl':'swr'):(y<0?'swu':'swd')))}};nm=true},
      touchcancel:function(e){nm=false}
     };
     d.addEventListener('touchstart',touch['touchstart'],false);
     d.addEventListener('touchmove',touch['touchmove'],false);
     d.addEventListener('touchend',touch['touchend'],false);
     d.addEventListener('touchcancel',touch['touchcancel'],false);
  })(document);

  function scrollOn(e) {
    document.documentElement.style.overflow = 'auto';
    document.body.scroll = "yes"; // ie only
  };

  function scrollOff(e) {

    if (!document.getElementsByClassName('js-carousel-jump')) {
      document.documentElement.style.overflow = 'hidden';
      document.body.scroll = "no";
    }

    else {
      document.documentElement.style.overflow = 'auto';
      document.body.scroll = "yes";
    }
  }

  for(let carousel of carousels) {
    carousel.addEventListener('fc',scrollOn,false);// 0-50ms vs 500ms with normal click
    carousel.addEventListener('swl',scrollOff,false);
    carousel.addEventListener('swr',scrollOff,false);
    carousel.addEventListener('swu',scrollOn,false);
    carousel.addEventListener('swd',scrollOn,false);
  }

  document.addEventListener('touchstart',scrollOn,false);
  document.addEventListener('click',scrollOn,false);
}
