/**
 * Open Video in Modal
 */

import moment from 'moment';

const modalClasses = {
 ENABLE_MODAL : 'modal-enabled',
 MODAL_OPEN : 'modal-open',
 PRE_LOADER: 'pre-loader',
 CONTACT_US_HTML: 'contact_us_html'
}

export function modalOverlay() {

  let openModalEls = document.getElementsByClassName('js-open-modal'),
     closeModalEl = document.getElementsByClassName('js-close-modal')[0],
     modalOverlayEl = document.getElementsByClassName('js-modal-overlay')[0],
     modalContainerEl = document.getElementsByClassName('js-modal-container')[0],
     bodyEl = document.getElementsByTagName('BODY')[0],
     video, dataSource, dataType, iframe, videoTimer, counter;

  for (let openModalEl of openModalEls) {
    openModalEl.addEventListener('click', openModal, false);
  }

  closeModalEl.addEventListener('click', closeModal, false);

  /**
   * This function opens the modal.
   */
  function openModal(e) {
    modalContainerEl.innerHTML = '';
    modalOverlayEl.classList.add(modalClasses.ENABLE_MODAL);
    bodyEl.classList.add(modalClasses.MODAL_OPEN);
    dataSource = this.dataset.source;
    dataType = this.dataset.type;

    if (dataType == 'hosted-video') {
      appendVideo(dataSource);
    } else if (dataType == 'embed-video') {
      appendIframe();
    } else {
      //dataSource should be same as ID of html to be fetched and appended from footer.html that holds all common html
      let html = document.createElement('div');
      html.classList.add('modal-content');
      html.innerHTML = document.getElementById(dataSource).innerHTML;
      modalContainerEl.appendChild(html);
    }
  }

  /**
   * This function to addend embedded YouTube videos.
   */
  function appendIframe() {
    iframe = document.createElement('iframe'),
    iframe.setAttribute( "frameborder", "0" );
    iframe.setAttribute( "allowfullscreen", "" );
    iframe.setAttribute('src', `https://www.youtube.com/embed/${dataSource}?autoplay=1&modestbranding=1&rel=0&amp&wmode=opaque&playsinline=1&version=3&controls=1&autohide=2&enablejsapi=1&origin=https//www.youtube.com&widgetid=1`);
    modalContainerEl.classList.add('model-iframe');
    modalContainerEl.innerHTML = "";
    modalContainerEl.appendChild(iframe);

    let xhttp = new XMLHttpRequest();

    xhttp.open('GET', `https://www.googleapis.com/youtube/v3/videos?key=AIzaSyA-PJ5CICCZESHXXOH0YhPDsd-kpd0AhPI&part=snippet,contentDetails&id=${dataSource}`, true);
    xhttp.onload = function () {
      if (this.status == 200) {
        let youtubeResponse = JSON.parse(xhttp.response);
        let utmDuration = moment.duration(youtubeResponse.items[0].contentDetails.duration).asSeconds();

        // atrack('video_view', window.getUtmParams({utm_video_id: youtubeResponse.items[0].snippet.title, elapsed_time: 0, utm_duration: utmDuration}));
      } else {
        // Something went wrong (404 etc.)
        console.error(this.statusText);
      }
    };
    xhttp.send();
  }

  /**
   * This function close the modal.
   */
  function closeModal() {
    clearInterval(videoTimer); //stop timer once video has ended or user has closed the modal
    modalOverlayEl.classList.remove(modalClasses.ENABLE_MODAL);
    bodyEl.classList.remove(modalClasses.MODAL_OPEN);
    modalContainerEl.innerHTML = "";
    modalContainerEl.classList.remove('model-iframe');
  }

  /**
  *PERF-1134 : Fire Pixel every 10sec of video playback (and on swipe of screenshots.. )
  */
  function videoPlay(src){
    clearInterval(videoTimer);
    videoTimer = setInterval(function(){
      // atrack('video_view', window.getUtmParams({utm_video_id: src.split(".")[0], elapsed_time: video.currentTime}));
      videoPlay(src);
    }, 10000);
  }

  /**
   * This function Created and Append the Video.
   */
  function appendVideo(dataSource) {
    video = document.createElement('video');
    video.setAttribute('controls','controls');
    video.setAttribute('controlsList','nodownload');
    video.src = `${dataSource}`;
    video.play();
    var filename = video.src.substring(video.src.lastIndexOf('/')+1);
    //video.setAttribute('onplay', videoPlay(filename));
    video.addEventListener('play',function() {
      videoPlay(filename);
    });
    video.addEventListener('loadedmetadata', function() {
      // atrack('video_view', window.getUtmParams({utm_video_id: filename.split(".")[0], elapsed_time: video.currentTime, utm_duration: video.duration}));
    });
    video.addEventListener('ended',function() {
      clearInterval(videoTimer); //stop timer once video is ended
      // atrack('video_view', window.getUtmParams({utm_video_id: filename.split(".")[0], elapsed_time: video.currentTime, video_end: true}));
    });
    // video.addEventListener('pause', function() {
    //   clearInterval(videoTimer); //stop timer once video is ended
    //   // atrack('video_view', window.getUtmParams({utm_video_id: filename.split(".")[0], elapsed_time: video.currentTime}));
    // });
    modalContainerEl.classList.add('pre-loader');
    modalContainerEl.innerHTML = '';
    modalContainerEl.appendChild( video );

    /**
     * Stop PreLoader once video started playing.
     */
    video.addEventListener('canplaythrough',function() {
      modalContainerEl.classList.remove('pre-loader');
    });

    /**
     * Close Modal once video is ended.
     */
    video.addEventListener('ended', closeModal, false);
  }
}
