/**
 * Step 2 user flow.
 */

const CLASSLIST = {
  TRACK_USER: 'js-track-user',
  PLAN_SELECTED: 'js-select-plan-join',
  HAS_SELECTED_PLAN: 'plan-selected'
}

export let trackUserJourney = () => {
  let trackUsers = document.getElementsByClassName(CLASSLIST.TRACK_USER),
      screenWidth = 768,
      mobilePickGift = "pick_your_free_gift",
      trackEvent, offerCode, hasHref, plansSelected, dataPlanDuration;

  !window.extraParams ? extraParams = window.extraParams = {} : extraParams = window.extraParams;

  // Check if class is present.
  if (trackUsers) {
    for (let trackUser of trackUsers) {
      trackUser.addEventListener('click', trackUserFlow, false);
		}
  }

  /**
   * Tracks user flow with the help of pixels.
   */
  function trackUserFlow() {
    trackEvent = this.getAttribute('data-track-user');
    offerCode = this.getAttribute('data-offer');
    hasHref = this.getAttribute('href');
    dataPlanDuration = this.getAttribute('data-plan-duration');
    plansSelected = document.getElementsByClassName(CLASSLIST.PLAN_SELECTED);

    /**
     * Mobile Only: Pass data-plan-duration as an extra parameter if present.
     */
    if (trackEvent === mobilePickGift) {
      for (let planSelected of plansSelected) {
        if (planSelected.classList.contains(CLASSLIST.HAS_SELECTED_PLAN)) {
          dataPlanDuration = planSelected.getAttribute('data-plan-duration');
          // atrack(trackEvent, window.getUtmParams({'utm_subscr_plan': dataPlanDuration}));
          return
        }
      }
    }

    extraParams.utm_cta_id = this.innerText;


    /**
     * Pass data-plan-duration as an extra parameter if presenta and added it to the extraParams object
     */
    if (dataPlanDuration) {
      extraParams.utm_subscr_plan = dataPlanDuration;
      if (offerCode != 'add-to-cart') {
          return
      }
    }


    /**
     * Check if data-offer attribute is present.
     */
    if (offerCode) {
      if (hasHref) {
        // atrack('add-to-cart', window.getUtmParams(extraParams));
      }
      extraParams.utm_promo_code = offerCode;
    }
    // atrack(trackEvent, window.getUtmParams(extraParams));
  }
}
